import './DevelopmentalProgression.scss';

import React, { useState, useMemo, useEffect, useRef } from 'react';
import { compact, uniqBy } from 'lodash';
import classnames from 'classnames';
import { Link } from 'gatsby';
import * as d3 from 'd3';

import * as Core from 'components/core';

import ContentWrapper from 'components/layout/ContentWrapper';
import { usePersistentState } from 'utils/usePersistentState';
import { useScrollPosition } from 'utils/useScrollPosition';

import { StateStandard, StateStandardsTerm } from 'types';

import useDomainDescriptions from 'data/hooks/useDomainDescriptions';

import Cell from './Cell';

const allAges = [
  'Pre-K',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
];

interface Props {
  stateStandards: StateStandard[];
  stateStandardsTerms: StateStandardsTerm[];
  initialStateStandardsID: number | null;
}

const DevelopmentalProgression: React.FC<Props> = (props) => {
  const { stateStandards, stateStandardsTerms, initialStateStandardsID } =
    props;

  const domainDescriptions = useDomainDescriptions();

  const [showSkills, setShowSkills] = useState(false);
  const [collapseEmpty, setCollapseEmpty] = useState(false);

  const [selectedStateStandardID, setSelectedStateStandardID] =
    usePersistentState<number | null>(
      'state-standards-alignment-selected-standard',
      null
    );

  const tableRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (initialStateStandardsID) {
      setSelectedStateStandardID(initialStateStandardsID);
    }
  }, [initialStateStandardsID, setSelectedStateStandardID]);

  const selectedStateStandardTerms = useMemo(() => {
    if (!selectedStateStandardID) {
      return null;
    }
    const terms = stateStandardsTerms.filter(
      (term) => term.frameworkID === selectedStateStandardID
    );

    return terms.filter((term) => term.tier === 1);
  }, [selectedStateStandardID, stateStandardsTerms]);

  const termsByAge = useMemo(() => {
    let index = 0;

    return compact(
      domainDescriptions.map((domain) => {
        const subdomains = compact(
          domain.subdomains.map((subdomain) => {
            const termsByAge = allAges.map((age) => {
              return uniqBy(
                selectedStateStandardTerms?.filter(
                  (term) =>
                    term.ages.includes(age) &&
                    term.skills.includes(subdomain.identifier)
                ),
                (term) => term.name
              );
            });

            const empty = !termsByAge.some((terms) => terms?.length);

            if (collapseEmpty && empty) {
              return null;
            }

            return {
              domain: subdomain,
              index: ++index,
              termsByAge
            };
          })
        );

        if (subdomains.length === 0) {
          return null;
        }

        return {
          domain,
          subdomains
        };
      })
    );
  }, [collapseEmpty, domainDescriptions, selectedStateStandardTerms]);

  const ages = useMemo(() => {
    if (!collapseEmpty) {
      return allAges.map((label, index) => ({ label, index }));
    }

    const subDomains = termsByAge.flatMap((domain) => domain.subdomains);

    return allAges
      .map((label, index) => ({ label, index }))
      .filter((_age, index) =>
        subDomains.some((subdomain) => subdomain.termsByAge[index]?.length)
      );
  }, [collapseEmpty, termsByAge]);

  const [hoveredCell, setHoveredCell] = useState<[number, number] | null>(null);

  const colorScales = useMemo(() => {
    return domainDescriptions.map((domain) =>
      d3.interpolate('#ffffff', domain.color)
    );
  }, [domainDescriptions]);

  const isHoveredRow = (row: number) => hoveredCell && row === hoveredCell[0];

  const tablePosition = tableRef.current
    ? tableRef.current?.getBoundingClientRect().top
    : 1;

  // Triggers a rerender on scroll, even though the value isn't used
  useScrollPosition();

  return (
    <div className="DevelopmentalProgression">
      <ContentWrapper>
        <div className="DevelopmentalProgression-controls">
          <div className="DevelopmentalProgression-select">
            <Core.Select
              value={selectedStateStandardID?.toString() || ''}
              onChange={(event) =>
                setSelectedStateStandardID(
                  event.currentTarget.value
                    ? parseInt(event.currentTarget.value)
                    : null
                )
              }
            >
              <option value="" />
              {stateStandards.map((stateStandard) => (
                <option
                  key={stateStandard.identifier}
                  value={stateStandard.identifier.toString()}
                >
                  {stateStandard.name}
                </option>
              ))}
            </Core.Select>
          </div>

          <div className="DevelopmentalProgression-options">
            <Core.CheckboxField
              checked={showSkills}
              onChange={(event) => setShowSkills(event.currentTarget.checked)}
              label="Show Skills"
            />

            <Core.CheckboxField
              checked={collapseEmpty}
              onChange={(event) =>
                setCollapseEmpty(event.currentTarget.checked)
              }
              label="Collapse Empty Rows and Columns"
            />

            {selectedStateStandardID && (
              <div>
                <Link
                  to={`/state-standards/${selectedStateStandardID}`}
                  className="DevelopmentalProgression-link"
                >
                  View Standards
                </Link>
              </div>
            )}
          </div>
        </div>
      </ContentWrapper>

      {selectedStateStandardID ? (
        <div className="DevelopmentalProgression-scrollContainer">
          <div
            className="DevelopmentalProgression-table"
            ref={tableRef as any}
            aria-live="polite"
          >
            <div
              className="DevelopmentalProgression-heading"
              style={{
                top: tablePosition < 0 ? `${-tablePosition}px` : 'auto'
              }}
            >
              <div
                className={classnames('DevelopmentalProgression-row', {
                  'is-large': showSkills
                })}
              >
                <div className="DevelopmentalProgression-frozenCell">
                  Sub Domain
                </div>

                {ages.map((age, columnIndex) => (
                  <div
                    key={age.index}
                    className={classnames(
                      'DevelopmentalProgression-cell DevelopmentalProgression-headingCell',
                      {
                        'is-hovered':
                          hoveredCell && columnIndex === hoveredCell[1]
                      }
                    )}
                  >
                    {columnIndex > 1 && 'Grade '}
                    {age.label}
                  </div>
                ))}
              </div>
            </div>

            {termsByAge.map((domain, domainIndex) => (
              <React.Fragment key={domainIndex}>
                <div
                  className={classnames('DevelopmentalProgression-row', {
                    'is-large': showSkills
                  })}
                >
                  <div className="DevelopmentalProgression-frozenCell">
                    <div className="DevelopmentalProgression-domainHeading">
                      <div
                        className="DevelopmentalProgression-domainIcon"
                        style={{ backgroundColor: domain.domain.color }}
                      />
                      {domain.domain.name}
                    </div>
                  </div>

                  {ages.map((_age, columnIndex) => (
                    <Cell
                      key={columnIndex}
                      showSkills={showSkills}
                      domainIndex={domainIndex}
                      columnIndex={columnIndex}
                      colorScales={colorScales}
                      hoveredCell={hoveredCell}
                    />
                  ))}
                </div>

                {domain.subdomains.map((row) => (
                  <div
                    key={row.domain.identifier}
                    className={classnames('DevelopmentalProgression-row', {
                      'is-large': showSkills
                    })}
                  >
                    <div
                      className={classnames(
                        'DevelopmentalProgression-frozenCell',
                        {
                          'is-hovered': isHoveredRow(row.index)
                        }
                      )}
                      onMouseEnter={() => setHoveredCell([row.index, -1])}
                      onMouseLeave={() => setHoveredCell(null)}
                    >
                      {row.domain.name}
                    </div>

                    {ages.map((age, columnIndex) => (
                      <Cell
                        key={columnIndex}
                        terms={row.termsByAge[age.index]}
                        showSkills={showSkills}
                        domainIndex={domainIndex}
                        rowIndex={row.index}
                        columnIndex={columnIndex}
                        colorScales={colorScales}
                        hoveredCell={hoveredCell}
                        setHoveredCell={setHoveredCell}
                        age={`${columnIndex > 1 ? 'Grade ' : ''}${age.label}`}
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className="DevelopmentalProgression-empty">
          Select a state standard to view its developmental progression
        </div>
      )}
    </div>
  );
};

export default DevelopmentalProgression;
