import './Tooltip.scss';

import React from 'react';

import { StateStandardsTerm } from 'types';

interface Props {
  terms: StateStandardsTerm[];
  age: string;
}

const Tooltip: React.FC<Props> = (props) => {
  const { terms, age } = props;

  return (
    <div className="DevelopmentalProgressionTooltip">
      {age} - {terms.length} {terms.length === 1 ? 'Term' : 'Terms'}
      <div className="DevelopmentalProgressionTooltip-arrow" />
    </div>
  );
};

export default Tooltip;
