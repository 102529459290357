import './developmental-progression.scss';

import React from 'react';
import { graphql } from 'gatsby';

import { config } from 'config';

import Layout from 'components/layout/Layout';
import ContentWrapper from 'components/layout/ContentWrapper';
import { StateStandard, StateStandardsTerm } from 'types';

import DevelopmentalProgression from 'components/developmental-progression/DevelopmentalProgression';

interface Props {
  location: {
    state: any;
  } | null;
  data: {
    allStateStandardsJson: {
      stateStandards: StateStandard[];
    };
    allStateStandardsTerms: {
      stateStandardsTerms: StateStandardsTerm[];
    };
  };
}

const DevelopmentalProgressionPage: React.FC<Props> = (props) => {
  const { stateStandards } = props.data.allStateStandardsJson;
  const { stateStandardsTerms } = props.data.allStateStandardsTerms;

  const initialStateStandardsID = props?.location?.state?.stateStandardsID;

  if (!config.stateStandardsEnabled) {
    return null;
  }

  return (
    <Layout fullWidthContent>
      <ContentWrapper>
        <div className="DevelopmentalProgressionPage-intro">
          <h1>Analyze State Standards Developmental Progressions</h1>
        </div>
      </ContentWrapper>

      <div className="DevelopmentalProgressionPage">
        <DevelopmentalProgression
          stateStandards={stateStandards}
          stateStandardsTerms={stateStandardsTerms}
          initialStateStandardsID={initialStateStandardsID}
        />
      </div>
    </Layout>
  );
};

export default DevelopmentalProgressionPage;

export const query = graphql`
  query StateStandardsDevelopmentalProgressionQuery {
    allStateStandardsJson {
      stateStandards: nodes {
        ageRange
        citation
        name
        name
        identifier
        link
        organization
        state
        stateAbbreviation
      }
    }
    allStateStandardsTerms: allStateStandardsTermsJson {
      stateStandardsTerms: nodes {
        identifier
        name
        parentID
        frameworkID
        tier
        ageDescription
        ages
        skills
      }
    }
  }
`;
