import './Cell.scss';

import React from 'react';
import classnames from 'classnames';

import { StateStandardsTerm } from 'types';

import Tooltip from './Tooltip';

interface Props {
  terms?: StateStandardsTerm[];
  age?: string;
  showSkills: boolean;
  domainIndex: number;
  rowIndex?: number;
  columnIndex: number;
  colorScales: ((t: number) => string)[];
  hoveredCell: [number, number] | null;
  setHoveredCell?(cell: [number, number] | null): void;
}

const Cell: React.FC<Props> = (props) => {
  const {
    terms,
    age,
    showSkills,
    domainIndex,
    rowIndex,
    columnIndex,
    colorScales,
    hoveredCell,
    setHoveredCell
  } = props;

  const hovered =
    hoveredCell &&
    rowIndex === hoveredCell[0] &&
    columnIndex === hoveredCell[1];

  const inHoveredRowOrColumn =
    hoveredCell &&
    (rowIndex === hoveredCell[0] || columnIndex === hoveredCell[1]);

  return (
    <div
      className={classnames('DevelopmentalProgressionCell', {
        'is-inHoveredRowOrColumn': inHoveredRowOrColumn,
        'is-hovered': hovered
      })}
      onMouseEnter={() => {
        setHoveredCell && rowIndex && setHoveredCell([rowIndex, columnIndex]);
      }}
      onMouseLeave={() => {
        setHoveredCell && setHoveredCell(null);
      }}
      aria-label={
        terms
          ? `${age}: ${terms.length} ${terms.length === 1 ? 'Term' : 'Terms'}`
          : ''
      }
    >
      {terms && (
        <>
          {showSkills ? (
            terms?.map((term) => (
              <div
                key={term.identifier}
                className="DevelopmentalProgressionCell-term"
              >
                {term.name}
              </div>
            ))
          ) : (
            <>
              <div
                className="DevelopmentalProgressionCell-heatMapCell"
                style={
                  terms?.length
                    ? {
                        backgroundColor: colorScales[domainIndex](
                          terms.length / 10
                        )
                      }
                    : { backgroundColor: 'transparent' }
                }
              />
              {hovered && age && <Tooltip terms={terms} age={age} />}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Cell;
